@font-face {
  font-family: "GT Pressura";
  font-style: normal;
  font-weight: bold;
  src: local("GT Pressura"),
    url("./assets/fonts/gt-pressura-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/gt-pressura-bold-webfont.woff") format("woff")
}

@font-face {
  font-family: "Archer";
  font-style: normal;
  font-weight: 700;
  src: local("Archer"),
    url("./assets/fonts/ArcherBoldPro.woff") format("woff"),
    url("./assets/fonts/Archer-Bold.otf") format("otf")
}

@font-face {
  font-family: "Archer";
  font-style: normal;
  font-weight: 600;
  src: local("Archer"),
    url("./assets/fonts/ArcherSemiboldPro.woff") format("woff"),
    url("./assets/fonts/Archer-Semibold.otf") format("otf")
}

@font-face {
  font-family: "Archer";
  font-style: normal;
  font-weight: 500;
  src: local("Archer"),
    url("./assets/fonts/ArcherMediumPro.woff") format("woff"),
    url("./assets/fonts/Archer-Medium.otf") format("otf")
}

@font-face {
  font-family: "Archer";
  font-style: normal;
  font-weight: 400;
  src: local("Archer"),
    url("./assets/fonts/ArcherBookPro.woff") format("woff"),
    url("./assets/fonts/Archer-Book.otf") format("otf")
}

@font-face {
  font-family: "Archer";
  font-style: italic;
  font-weight: 400;
  src: local("Archer"),
    url("./assets/fonts/ArcherBookItalPro.woff") format("woff"),
    url("./assets/fonts/Archer-BookItal.otf") format("otf")
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color:transparent;
  outline: none;
}

html {
  background-color: #9FD9B4 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100 + 1px); /* corrected viewport height using css custom variables */
  background: #DDD9D4;
  font-weight: 500;
  background-color: #9FD9B4 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  line-height: 1.5;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover, a:focus {
  text-decoration: underline;
}

img {
  max-width: 100%;
  margin-bottom: 1rem;
}

button {
  border: none;
  background-color: transparent;
}

ul {
  margin: 0.5rem 0;
}

ul li {
  margin: 0;
  list-style: none;
}

button {
  appearance: none;
}

.dropdown-wrapper {
  position: relative;
}

#application-canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#application-splash-wrapper {
  display: none !important;
}

#root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 899;
  pointer-events: none;
}

.eighth-wall-logo {
  max-width: 110px;
  position: absolute;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%);
  margin-bottom: 0;
}


.skip-btn {
  font-size: 0.75rem;
  font-family: "Archer", sans-serif;
  text-align: center;
  display: block;
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translate(-50%, -50%);
  z-index: 801;
  pointer-events: auto;
  color: #454545;
  text-decoration: underline;
}